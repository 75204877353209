import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/contact/Content'

export const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us - Four Fox Security Pvt. Ltd.";
  }, []);

  return (
    <div>
        <Navbar/>
        <Content/>
        <Footer/>
    </div>
  )
}
