import { Carousel } from 'react-bootstrap';
import React from 'react';
import about from '../../images/aboutUs.jpg';
import { Link } from 'react-router-dom';

export const Content = () => {
  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
          </div>
          <img width="100%" src={about} alt="banner" className='header-banner'/>
        </Carousel.Item>
      </Carousel>
      <div className='about-content'>
        <p>
          We are
          <Link to="/" className='text-decoration-none'><span style={{ color: 'rgb(0, 8, 255)' }}> <b>Four Fox Security</b> </span></Link>, a private security company based in <i>Tehsil Ghanari, Una District, Himachal Pradesh, 177204</i>.<br /> Our Director <b>Mr. Sub. Ashok Kumar</b> leads our highly skilled team committed to providing exceptional security services to our clients.
        </p><br />
        <p>
          We strive to maintain high standards of integrity and professionalism in all our work and work tirelessly to ensure the safety and peace of mind of our clients. We have a wide range of security services, from <b>surveillance and protection to secure transportation and security solutions for events</b>.
        </p><br />
        <p>
          We are committed to providing exceptional service and building lasting relationships with our clients. If you're looking for a trusted security company, don't hesitate to <Link to="/contact" className='text-decoration-none'><span style={{ color: 'rgb(0, 8, 255)' }}> contact us </span></Link> today.
        </p>
      </div>
      
    </div>
  )
}
