import React, { useEffect } from 'react'
import { Banner } from '../core/Banner'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/home/Content'

export const Home = () => {
  useEffect(() => {
    document.title = "Four Fox Security Manpower Services Private Limited";
  }, []);

  return (
    <div>
      <Navbar />
      <Banner />
      <Content />
      <Footer />
    </div>
  )
}
