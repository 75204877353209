import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ScrollToTop } from './core/ScrollToTop';
import { About } from './pages/About';
import { Clients } from './pages/Clients';
import { Contact } from './pages/Contact';
import { Home } from './pages/Home';
import { Services } from './pages/Services';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/services' element={<Services />}></Route>
          <Route path='/clients' element={<Clients />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
