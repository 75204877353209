import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { NavbarDrawer } from "./NavbarDrawer";
import navbarLogo from '../images/navbar-logo.jpg';
import { Modal } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
    navlinks: {
        display: "flex",
        justifyContent: 'end'
    },
    logo: {
        marginLeft: '5%',
        flexGrow: "2",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "black",
        fontSize: "18px",
        fontWeight: "600",
        paddingLeft: "30px",
        paddingTop: '5px',
        paddingRight: '30px',
        "&:hover": {
            color: "rgb(0, 8, 255, 0.7)",
        },
    },
}));

export const Navbar = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const url = (window.location.href).split('/')[3];
    const [colorChange, setColorChange] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (url === "") {
            setTimeout(() => {
                setShow(true);
            }, 5000);
        }
        // eslint-disable-next-line
    }, [])

    const changeNavbarColor = () => {
        if (window.scrollY >= 400) {
            setColorChange(true);
        }
        else {
            setColorChange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    const handleClose = () => setShow(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(name, email, phone, query);
    }

    return (
        <>
            <AppBar position="static" className="security-navbar" style={{
                backgroundColor:
                    colorChange ? '#a9a9a9' : 'transparent'
            }}>
                <Toolbar>
                    <Typography variant="h4" className={classes.logo}>
                        <div className="site--logo" data-toggle="tooltip" data-placement="top" title="Assignment Pundit">
                            <a href="/" className="custom-logo-link" rel="home" aria-current="page">
                                <img width="175" height="80" src={navbarLogo} className="custom-logo" alt="Assignment Pundit" />
                            </a>
                        </div>
                    </Typography>
                    {isMobile ? (
                        <NavbarDrawer />
                    ) : (
                        <div className={classes.navlinks} style={{ width: '100%' }}>
                            {
                                url === "" ?
                                    <Link to="/" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        <span>Home</span>
                                    </Link>
                                    :
                                    <Link to="/" className={classes.link}>
                                        <span>Home</span>
                                    </Link>
                            }
                            {
                                url === "services" ?
                                    <Link to="/services" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        Services
                                    </Link>
                                    :
                                    <Link to="/services" className={classes.link}>
                                        Services
                                    </Link>
                            }
                            {
                                url === "clients" ?
                                    <Link to="/clients" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        <span>Clients</span>
                                    </Link>
                                    :
                                    <Link to="/clients" className={classes.link}>
                                        <span>Clients</span>
                                    </Link>
                            }
                            {
                                url === "about" ?
                                    <Link to="/about" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        About Us
                                    </Link>
                                    :
                                    <Link to="/about" className={classes.link}>
                                        About Us
                                    </Link>
                            }
                            {
                                url === "contact" ?
                                    <Link to="/contact" className={classes.link} style={{ color: 'rgb(0, 8, 255, 0.9)' }}>
                                        Contact Us
                                    </Link>
                                    :
                                    <Link to="/contact" className={classes.link}>
                                        Contact Us
                                    </Link>
                            }
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Modal show={show} onHide={handleClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ marginRight: '0px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Your Query</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='g-3' onSubmit={handleSubmit}>
                        <div className='col-12'>
                            <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder='Full Name' id='name' className='form-control inputbox' required
                                value={name} onChange={e => setName(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Email<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder='Email' className='form-control inputbox' required
                                value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Phone<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder='Phone' className='form-control inputbox' required
                                value={phone} onChange={e => setPhone(e.target.value)} />
                        </div>
                        <div className='col-12'><br />
                            <label className='form-label'>Send Your Query <span style={{ color: 'red' }}> *</span></label>
                            <textarea type="text" placeholder='Comment or Message' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                value={query} onChange={e => setQuery(e.target.value)} />
                        </div>
                        <div className='row g-3'>
                            <div className='col-md-3'></div>
                            <div className='col-md-6'><br />
                                <button className='btn btn-primary btn-contact' style={{ width: '100%' }}>
                                    {
                                        loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )
                                    }{" "}
                                    Send Now
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}