import React, { useEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/about/Content'

export const About = () => {
  useEffect(() => {
    document.title = "About Us - Four Fox Security Pvt. Ltd.";
  }, []);

  return (
    <div>
      <Navbar />
      <Content />
      <Footer />
    </div>
  )
}
