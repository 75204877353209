import { Card, Carousel } from 'react-bootstrap';
import React from 'react';
import career from '../../images/career.jpg';

export const Content = () => {
  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
          </div>
          <img width="100%" src={career} alt="banner" className='header-banner' />
        </Carousel.Item>
      </Carousel>
      <div className='row g-3 services-listing'>
        <h3 style={{ padding: '3%' }}>Our Clients</h3>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
          <Card>
            <Card.Body>
              <Card.Text>
                Tigaksha Metallics Pvt Ltd. (Three plants- Gagret, Ambota and Shimla )
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className='col-md-3'></div>
      </div>
    </div>
  )
}
