import { Card, Carousel } from 'react-bootstrap';
import React from 'react';
import service from '../../images/services.jpg';
import { Link } from 'react-router-dom';

export const Content = () => {
  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
          </div>
          <img width="100%" src={service} alt="banner" className='header-banner'/>
        </Carousel.Item>
      </Carousel>
      <div className='row g-3 services-listing'>
        <h3 style={{padding:'3%'}}>We will be providing all types of security services. We can provide security services to</h3>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Factories
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Banks and ATMs
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Transportation vans/ Cash vans
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Companies
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Apartments/ Home
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Schools
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row g-3 services-listing'>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Body guards
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
        <div className='col-md-4'>
          <Link to="/contact" className='text-decoration-none'>
            <Card>
              <Card.Body>
                <Card.Text>
                  Events security
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </div>
    </div>
  )
}
