import { Carousel } from 'react-bootstrap';
import React, { useState } from 'react';
import contact from '../../images/contactUs.jpg';
import axios from 'axios';

export const Content = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");

  const handleContact = (e) => {
    e.preventDefault();

    const data = {
      Name: firstName + lastName,
      Email: email,
      Phone: phone,
      Service: service,
      Description: description
    }

    axios.post("https://sheet.best/api/sheets/13a7f798-9728-4e51-8f80-f2dc9d1483b0", data)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className='banner-overlay'>
          </div>
          <img width="100%" src={contact} alt="banner" className='header-banner' />
        </Carousel.Item>
      </Carousel>
      <div>
        <form className='row g-3 contact-form' onSubmit={handleContact}>
          <div className='col-md-5'>
            <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder="First Name" required
              value={firstName} onChange={e => setFirstName(e.target.value)} />
          </div>
          <div className='col-md-5'>

            <label className='form-label'><span style={{ color: 'transparent' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder="Last Name" required
              value={lastName} onChange={e => setLastName(e.target.value)} />
          </div>
          <div className='col-md-5'>
            <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder="Email" required
              value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='col-md-5'>
            <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder="Phone" required
              value={phone} onChange={e => setPhone(e.target.value)} />
          </div>
          <div className='col-md-10'>
            <label className='form-label'>Services Required <span style={{ color: 'red' }}> *</span></label>
            <input className='form-control inputbox' type="text" placeholder="Service" required
              value={service} onChange={e => setService(e.target.value)} />
          </div>
          <div className='col-12'>
            <label className='form-label'>Description <span style={{ color: 'red' }}> *</span></label>
            <textarea className='form-control inputbox' type="text" placeholder="Short Description about required service" style={{ minHeight: '150px' }} required
              value={description} onChange={e => setDescription(e.target.value)} />
          </div>
          <div className='col-md-4'><br />
            <button type='submit' className='btn btn-primary btn-contact'>
              Contact Now
            </button>
          </div>
        </form>
        <h5 className='contact-form' style={{ padding: '0% 20% 5% 20%' }}>
          Call us at <a href="tel:8894332855" target="_blank" rel="noreferrer" className='text-decoration-none'>+918894332855</a> , <a href="tel:7018994829" target="_blank" rel="noreferrer" className='text-decoration-none'>+917018994829</a>
        </h5>
      </div>
    </div>
  )
}
