import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../images/banner-image1.jpeg';
import image2 from '../images/banner-image4.jpeg';
import image3 from '../images/banner2.jpg';
import image4 from '../images/banner3.jpg';

export const Banner = () => {

    return (
        <>
            <div>
                <Carousel variant="light">
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image2}
                            alt="FourFox Security"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image1}
                            alt="FourFox Security"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image4}
                            alt="FourFox Security"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image3}
                            alt="FourFox Security"
                        />
                    </Carousel.Item>
                </Carousel >
            </div>
        </>
    );
}